<template>
  <v-container fluid class="width-100" v-if="getPermission('setting:view')">
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 85vh; position: relative"
    >
      <v-card flat class="custom-grey-border remove-border-radius">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Quotation signature for customer
              </h3>
              <!-- <pre>{{emailQuotationTemplates}}</pre> -->
            </v-col>
            <v-col md="6" class="text-right py-0">
              <v-btn
                small
                class="text-white"
                color="cyan"
                :disabled="pageLoading"
                :loading="pageLoading"
                v-on:click="updatePdfSetting"
              >
                <span class="font-size-16 font-weight-600">Update</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="p-6 font-size-16">
          <table class="width-100">
            <tbody>
              <tr class="custom-border-top">
                <td
                  colspan="2"
                  class="pb-4 font-weight-600 font-size-16 color-custom-blue"
                >
                  <template>
                    <div class="d-flex mb-3" :key="index">
                      <p
                        class="my-auto m-0 mr-4"
                        style="width: 200px !important"
                      >
                        Show Quotation signature
                      </p>
                      <p class="my-auto m-0 mx-4">
                        <v-switch
                          inset
                          color="green"
                          class="m-0"
                          hide-details
                          v-model="signature.value"
                        ></v-switch>
                      </p>
                      <div
                        v-if="signature && signature.field"
                        style="height: 200px"
                        class="cursor-pointer"
                      >
                        <v-img
                          contain
                          style="height: 200px"
                          lazy-src="https://fakeimg.pl/450x450"
                        ></v-img>
                      </div>
                    </div>
                  </template>

                  <!--  <div class="d-flex mt-3">
                          <p class="my-auto m-0 mr-19">Show Service Address</p>
                          <p class="my-auto m-0 mx-4">
                            <v-switch
                              inset
                              color="green"
                              class="m-0"
                              hide-details
                            ></v-switch>
                          </p>
                        </div> -->
                </td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
      </v-card>
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import { GET, PATCH } from "@/core/services/store/request.module";
/*   import TinyMCE from "@/view/pages/partials/TinyMCE.vue"; */
import { map, toSafeInteger } from "lodash";

export default {
  name: "profile-quotation-setting",
  data() {
    return {
      pageLoading: true,
      signature: {},
    };
  },

  components: {
    /* editor: TinyMCE, */
  },
  methods: {
    getQuotationSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/quotation" })
        .then(({ data }) => {
          _this.setting = data;
          _this.signature = data.signature;
          _this.pdf_settings = data.pdf_settings;
          map(data.pdf_settings, function (row, index) {
            _this.pdf_settings[index].value = toSafeInteger(row.value);
          });
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updatePdfSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "setting/pdf-setting",
          data: {
            pdf_settings: _this.pdf_settings,
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.getQuotationSetting();
  },
};
</script>
