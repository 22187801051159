<template>
  <v-container fluid class="width-100" v-if="getPermission('setting:view')">
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 85vh; position: relative"
    >
      <v-card
        flat
        class="custom-setting-height custom-grey-border remove-border-radius"
      >
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Property #
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="6" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updatePropertySetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="4" class="pb-4">
              <label>Prefix</label>
              <v-text-field
                v-model.trim="setting.prefix"
                dense
                filled
                label="Prefix"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="4" class="pb-4">
              <label>Next Number</label>
              <v-text-field
                v-model.trim="setting.next_number"
                dense
                filled
                label="Next Number"
                solo
                flat
                type="number"
                min="0"
                max="999"
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="4" class="pb-4">
              <label>Number Length</label>
              <v-text-field
                v-model.trim="setting.number_length"
                dense
                filled
                label="Number Length"
                solo
                flat
                type="number"
                min="0"
                max="15"
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!--  <v-card flat class="custom-grey-border remove-border-radius mt-4">
          <v-card-title class="headline grey lighten-4">
            <v-row>
              <v-col md="6" class="py-0 my-auto">
                <h3
                  class="font-weight-700 custom-headline color-custom-blue"
                  style="margin: 3.5px 0px"
                >
                  Property Remarks
                </h3>
              </v-col>
              <template v-if="getPermission('setting:update')">
                <v-col md="6" class="text-right py-0">
                  <v-btn
                    small
                    class="text-white"
                    color="cyan"
                    v-on:click="updatePropertySetting"
                    :disabled="pageLoading"
                    :loading="pageLoading"
                  >
                    <span class="font-size-16 font-weight-600">Update</span>
                  </v-btn>
                </v-col>
              </template>
            </v-row>
          </v-card-title>
          <v-card-text
            class="p-6 font-size-16"
            :class="{ 'pointer-events-none': !getPermission('setting:update') }"
          >
            <v-row>
              <v-col md="12" class="pb-4">
                <v-textarea
                  v-model.trim="setting.remarks"
                  auto-grow
                  dense
                  filled
                  label="Remarks"
                  solo
                  flat
                  row-height="30"
                  color="cyan"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card> -->
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import { GET, PATCH } from "@/core/services/store/request.module";

export default {
  name: "profile-property-setting",
  data() {
    return {
      pageLoading: true,
      setting: {
        prefix: null,
        next_number: null,
        number_length: null,
        remarks: null,
      },
    };
  },
  methods: {
    getPropertySetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/property" })
        .then(({ data }) => {
          _this.setting = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updatePropertySetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, { url: "setting/property", data: _this.setting })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.getPropertySetting();
  },
};
</script>
