<template>
  <v-container fluid class="width-80" v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          INCIDENT REPORT
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 85vh; position: relative"
    >
      <v-card
        v-if="false"
        flat
        class="custom-setting-height custom-grey-border remove-border-radius"
      >
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                INCIDENT REPORT #
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="6" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateIncidentSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="4" class="pb-4">
              <label>Prefix</label>
              <v-text-field
                v-model.trim="setting.prefix"
                dense
                filled
                label="Prefix"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="4" class="pb-4">
              <label>Next Number</label>
              <v-text-field
                v-model.trim="setting.next_number"
                dense
                filled
                label="Next Number"
                solo
                flat
                type="number"
                min="0"
                max="999"
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="4" class="pb-4">
              <label>Number Length</label>
              <v-text-field
                v-model.trim="setting.number_length"
                dense
                filled
                label="Number Length"
                solo
                flat
                type="number"
                min="0"
                max="15"
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Incident Impact
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="6" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  v-on:click="updateContractSetting"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-container>
            <v-row>
              <v-col md="12">
                <table class="width-100">
                  <tbody>
                    <tr v-for="(type, index) in designation" :key="index">
                      <td>
                        <v-text-field
                          v-model.trim="type.text"
                          dense
                          filled
                          label="Impact"
                          solo
                          flat
                          hide-details
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          color="cyan"
                        ></v-text-field>
                      </td>
                      <td width="50" v-if="designation.length > 1">
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-on:click="deleteContractType(index)"
                              color="deep-orange"
                              v-bind="attrs"
                              v-on="on"
                              >mdi-delete</v-icon
                            >
                          </template>
                          <span>Click here to delete</span>
                        </v-tooltip>
                      </td>
                      <td width="50">
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-on:click="createContractType(index)"
                              color="cyan"
                              v-bind="attrs"
                              v-on="on"
                              >mdi-plus</v-icon
                            >
                          </template>
                          <span>Click here to update</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <!-- <v-card flat class="custom-grey-border remove-border-radius mt-4">
          <v-card-title class="headline grey lighten-4">
            <v-row>
              <v-col md="6" class="py-0 my-auto">
                <h3
                  class="font-weight-700 custom-headline color-custom-blue"
                  style="margin: 3.5px 0px"
                >
                  Service Description
                </h3>
              </v-col>
              <template v-if="getPermission('setting:update')">
                <v-col md="6" class="text-right py-0">
                  <v-btn
                    small
                    class="text-white"
                    color="cyan"
                    v-on:click="updateServiceSetting"
                    :disabled="pageLoading"
                    :loading="pageLoading"
                  >
                    <span class="font-size-16 font-weight-600">Update</span>
                  </v-btn>
                </v-col>
              </template>
            </v-row>
          </v-card-title>
          <v-card-text
            class="p-6 font-size-16"
            :class="{ 'pointer-events-none': !getPermission('setting:update') }"
          >
            <v-row>
              <v-col md="12" class="pb-4">
                <v-textarea
                  v-model.trim="setting.description"
                  auto-grow
                  dense
                  filled
                  label="Description"
                  solo
                  flat
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  row-height="30"
                  color="cyan"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card> -->
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import { GET, PATCH } from "@/core/services/store/request.module";
export default {
  name: "profile-service-setting",
  data() {
    return {
      pageLoading: true,
      setting: {
        prefix: null,
        next_number: null,
        number_length: null,
        description: null,
      },
      designation: [
        {
          text: null,
        },
      ],
      imapactList: [],
    };
  },
  methods: {
    createContractType() {
      this.designation.push({
        text: null,
      });
    },
    deleteContractType(index) {
      if (this.designation.length > 1) {
        this.designation.splice(index, 1);
      }
    },
    updateContractSetting() {
      const _this = this;

      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "setting/impact",
          data: { imapactData: _this.designation },
        })
        .then(() => {
          /*  _this.$emit("get-report", true); */
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getIncidentSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "incident-report/setting" })
        .then(({ data }) => {
          _this.setting = data;
          _this.imapactList = this.lodash.map(
            data.impact,
            function (row, index) {
              if (_this.designation[index]) {
                _this.designation.push({
                  text: row.text,
                });
              }
            }
          );
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateIncidentSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, { url: "setting/incident", data: _this.setting })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.getIncidentSetting();
  },
};
</script>
